.login {
  background-image: url("../background.jpeg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  &__backdrop {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(10px);
    height: 100vh;
    width: 100%;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 0 10px;
  }

  &__footer {
    font-size: 16px;
  }

  &__subheader {
    margin: unset;
  }

  &__password-input {
    margin: 50px 5px 0px;
  }

  &__password-button {
    margin: 5px;
    border: none;
    border-radius: 5px;
    padding: 7px 15px;
    font-size: 16px;
    color: #555;
    background-color: lavender;
  }
}
